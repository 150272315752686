import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
// import Layout from "../components/layout"
import SEO from "../helpers/seo"
import Img from "gatsby-image"

const CaseStudyShortForm = ({ data }) => {
  const {
    title,
    clientName,
    ask,
    scope,
    solution,
    gallery,
  } = data.contentfulCaseStudyShortForm

  return (
    <React.Fragment>
      <Helmet>
        <body className="case-study-short-form-page" />
      </Helmet>
      <SEO title={title} />
      <div className="content-wrapper">
        <div className="intro">
          <div className="content">
            <div className="title">CLIENT</div>
            <p>{clientName}</p>
          </div>
          <div className="content">
            <div className="title">ASK</div>
            <p>{ask.ask}</p>
          </div>
          <section
            className="single-image mobile-show"
            style={{ marginBottom: 40 }}
          >
            {gallery[0].file.contentType.includes("video") ? (
              <div className="gatsby-image-wrapper">
                <video
                  width="100%"
                  height="auto"
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ objectFit: "cover" }}
                >
                  <source
                    src={gallery[0].file.url}
                    type={gallery[0].file.contentType}
                  />
                </video>
              </div>
            ) : (
              <Img fluid={gallery[0].fluid} alt={gallery[0].title} />
            )}
          </section>
          <div className="content">
            <div className="title">SCOPE</div>
            <p>{scope.scope}</p>
          </div>
          <div className="content">
            <div className="title">SOLUTION</div>
            <p>{solution.solution}</p>
          </div>
        </div>
        <div className="case-study-gallery">
          {gallery.map((val, index) => {
            if (val.file.contentType.includes("video")) {
              return (
                <div className="gatsby-image-wrapper" key={index}>
                  <video
                    width="100%"
                    height="auto"
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{ objectFit: "cover" }}
                  >
                    <source src={val.file.url} type={val.file.contentType} />
                  </video>
                </div>
              )
            } else {
              return (
                <Img
                  fluid={val.fluid}
                  alt={val.title}
                  key={index}
                  className={index === 0 ? "desktop-show" : null}
                />
              )
            }
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CaseStudyShortForm

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulCaseStudyShortForm(slug: { eq: $slug }) {
      clientName
      title
      solution {
        solution
      }
      ask {
        ask
      }
      scope {
        scope
      }
      gallery {
        file {
          contentType
          url
        }
        title
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
